import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import 'animate.css/animate.compat.css';
import ScrollAnimation from 'react-animate-on-scroll';

import Layout from '../components/layout';

const Boarding = ({ data }) => {
  var pageTitle = 'Pet Boarding';

  return (
    <Layout
      slug="boarding"
      title={pageTitle}
      description="Find out why we are the number one choice for boarding in central Alberta!"
    >
      <h1>{pageTitle}</h1>

      <h2>I'm boarding a:</h2>
      <div className="flex flex-col md:flex-row text-center mb-4">
        <Link
          className="btn btn-light mb-3 md:mb-0 md:mr-3"
          to="/dog-boarding/"
        >
          Dog
        </Link>
        <Link
          className="btn btn-light mb-3 md:mb-0 md:mr-3"
          to="/cat-boarding/"
        >
          Cat
        </Link>
        <Link className="btn btn-light" to="/small-boarding/">
          Small Animal
        </Link>
      </div>
      <div className="text-center">
        <p className="text-xl text-vip-purple text-bold my-8">
          Very Important Pets, because when it comes to your pet we understand
          that one size does not fit all!
        </p>
      </div>
      <p className="mb-20">
        Our amenities include everything from standard pet suites to private
        luxury suites which include a fireplace and couch. All of our pet suites
        include 24/7 indoor/outdoor access. Additionally, dog will have access
        to our expansive play yards which cover more than 30,000 sq. ft. of
        outdoor space and cats have specially designed suites which create a
        relaxing yet stimulating environment.
      </p>
      <section className="flex flex-col lg:flex-row-reverse lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Residences on Property</h2>
            <p>
              Having two residences on the property allows us to be with your
              pet to alleviate any anxiety or stress during thunderstorms or
              other extreme weather. We have even slept in the kennel on
              multiple occasions to ensure your pets are at ease! During the day
              we have a radio playing in the kennel however at night it is quiet
              to replicate a home environment. We are committed to doing
              whatever it takes to ensure your pet has the best stay possible.
              Please contact us if you have any additional questions!
            </p>
          </ScrollAnimation>
        </div>
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image6)}
              alt="house"
              className="mb-4 mx-auto res-square-400 rounded-xl"
              imgStyle={{
                objectPosition: 'left center',
              }}
            />
          </ScrollAnimation>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Security</h2>
            <p>
              At Very Important Pets we have many different safety and security
              features to ensure the well-being of your pets. Having two
              residences on the same property as the kennel provides peace of
              mind overnight. We have indoor and outdoor security cameras as
              well as a state of the art monitoring system for temperature, co2
              and smoke. This system allows us to check in from anywhere at any
              time of the day or night. Smoke alarms link directly to emergency
              personnel for the quickest reaction time. All of our kennels have
              a wire mesh roof to prevent any animals from getting in or out. In
              addition the private outdoor runs have a wire underlay to prevent
              your dog from digging out. Exterior fences also have a wire
              underlay to prevent digging and all fences are 6-7ft high. All
              gated areas are double latched and doors to the kennel remain
              locked to ensure that only authorized personnel can entering. Each
              entrance to the outside has a double gated catch pen for added
              security.
            </p>
          </ScrollAnimation>
        </div>
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image2)}
              alt="Security Image"
              className="mb-4 mx-auto res-square-400 rounded-xl"
              imgStyle={{ objectPosition: 'center 26%' }}
            />
          </ScrollAnimation>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row-reverse lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Help Us, Help You</h2>
            <p>
              At Very Important Pets we understand how important the small
              things can be. This is why we strongly encourage customer input on
              how to help your pet settle in best and how to keep them
              entertained. We encourage you to bring your pets own belongings so
              they have something familiar during their stay. We realize that
              every pet has different needs and requirements while in our care
              and that is why we will administer medications and accommodate
              extra health care requirements free of charge. We will also feed
              specialty diets including raw food as a free service; however, we
              do ask that you have the food pre-measured. We always reccomend
              bringing extra food just in case and this is more important with
              specialty diets.
            </p>
          </ScrollAnimation>
        </div>

        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image5)}
              alt="dog playing"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section className="flex flex-col items-center text-center mb-20">
        <span className="h2">
          Wondering who will be working with your pets?
        </span>
        <Link to="/about/#boarding-team" className="btn btn-light">
          Meet the Boarding Team
        </Link>
      </section>

      <section className="flex flex-col lg:flex-row lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Social Stays</h2>
            <p>
              Fun and safety are our two main objectives in the doggie daycare
              programs. Dogs are matched up in small, supervised groups with
              other dogs of a similar size and temperament. Overnight guests in
              the kennel who come at least montlhy or are staying four days or
              longer and meet our requirements are welcome in our doggy daycare.
              All dogs are carefully screened first.
            </p>
            <p>
              *Note* We reserve the right to refuse any dog or to accommodate
              any boarding dogs that do not meet our temperament requirements in
              our private pet suites.
            </p>
          </ScrollAnimation>
        </div>

        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image7)}
              alt="dogs playing"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row-reverse lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>We're all about FUN</h2>
            <p>
              A happy pet requires both mental and physical activity. We have
              multiple enrichment activities available such as climbing
              structures, puzzle bowls, treat stuffed toys and more. We also
              play games such as hiding treats for pets to sniff out and of
              course more traditional favourites like fetch for the canines and
              bird watching for our feline visitors. In addition, according to
              the IBSPA the size of our play yards is in the top 1% in all of
              North America! We also have multiple indoor play spaces to utilize
              during the cold winter days. Let us know what activities your pet
              prefers!
            </p>
          </ScrollAnimation>
        </div>
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image3)}
              alt="dog puzzle"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Pick Up &amp; Drop Off</h2>
            <p>
              We understand how busy life can get which is why we offer pick up
              and drop off services for surrounding communities. This service is
              provided at an additional charge if we are making a special trip
              to pick up your pet. If we happen to already be in the town then
              we offer this at a discounted rate of $10. Please contact us for
              individual pricing.
            </p>
          </ScrollAnimation>
        </div>
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image1)}
              alt="vehicle"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row-reverse lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Extras</h2>
            <p>
              Since we have multiple staff members who are groomers, as an
              additional service we offer exit baths, nail trims and other
              grooming services. This service is available for both canines and
              felines. For more information about our grooming services please
              visit our <Link to="/grooming/">grooming page</Link>.
            </p>
          </ScrollAnimation>
        </div>
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image4)}
              alt="dog"
              className="mb-4 mx-auto res-square-400 rounded-xl"
              imgStyle={{
                objectPosition: 'center 15%',
              }}
            />
          </ScrollAnimation>
        </div>
      </section>

      <Link className="btn btn-light mr-3" to="/book-now/">
        Book Now!
      </Link>
      <Link className="btn btn-dark" to="/kennel-tours/">
        Take a Tour
      </Link>
    </Layout>
  );
};

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "dog_car.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "security.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image3: file(relativePath: { eq: "toys.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image4: file(relativePath: { eq: "dog_grooming6.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image5: file(relativePath: { eq: "dog_playing.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image6: file(relativePath: { eq: "play_yard.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image7: file(relativePath: { eq: "daycare.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

export default Boarding;
